<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card title="Sortir" collapsed :badge="$store.getters['stockopnames/isFiltered'] ? 'Filtered':''">
        <template slot="body">
          <form @submit.prevent="submitFilter">
            <b-row>
              <b-col sm="12">
                <form-select-new :filter-id="idBranchArray" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="filter.branch" label="Cabang Asal"></form-select-new>
              </b-col>
              <b-col sm="6">
                <form-date :max="maxDate" v-model="filter.date_1" label="Tanggal Mulai"></form-date>
              </b-col>
              <b-col sm="6">
                <form-date :min="minDate" v-model="filter.date_2" label="Tanggal Selesai"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select-new url="v1/stock_opname_type_select2" v-model="filter.type" label="Tipe"></form-select-new>
              </b-col>
              <b-col sm="12">
                <button-action size="sm" @clicked="submitFilter" name="Filter" featherIcon="FilterIcon"></button-action>
                <button-action size="sm" @clicked="resetFilter" name="Reset" variant="warning"></button-action>
                <button-action size="sm" @clicked="exportExcel" variant="warning" name="Export"></button-action>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Daftar Stok Opname">
        <template slot="button">
          <button-add v-if="cP(109)" :to="{name: 'stockopnames-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables sort-key="2" desc ref="datatables" endpoint="v1/stock_opnames" :query-params="filterQuery" :fields="fields">
            <template v-slot:2="{item}">
              {{item[2]|mindate}}
            </template>
            <template v-slot:5="{item}">
              {{item[5]|formatNumber}}
            </template>
            <template v-slot:6="{item}">
              <button-link variant="success" size="sm" :to="{name:'stockopnames-show',params:{id: item[6]}}" feather-icon="FolderIcon"></button-link>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAction from '@/my-components/ButtonAction.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import FormSelectNew from '@/my-components/FormSelectNew'
import FormDate from '@/my-components/FormDate'
import ButtonLink from '@/my-components/ButtonLink.vue'
import { mapFields } from 'vuex-map-fields'
import utils from './utils'
import FileSaver from 'file-saver'

export default {
  components:{
    DataTables,
    FormSelectNew,
    FormDate,
    ButtonAction,
    ButtonAdd,
    ButtonLink
  },
  data() {
    return {
      utils:utils,
      fields: {
        0:{sortable: true, searchable: true, label:'Kode'},
        1:{sortable: true, searchable: true, label:'Cabang'},
        2:{sortable: true, searchable: true, label:'Tanggal'},
        3:{sortable: true, searchable: true, label:'Tipe'},
        4:{sortable: true, searchable: true, label:'Catatan'},
        5:{sortable: true, searchable: true, label:'Total Selisih'},
        6:{sortable: false, searchable: false, label:''},
      }
    }
  },
  computed:{
    ...mapFields('stockopnames',['filter']),
    userProfile(){
      return this.$store.state.auth.profile
    },
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    },
    filterQuery(){
      let filters = JSON.parse(JSON.stringify(this.filter))
      if(filters.branch) filters.id_branch = filters.branch.value
      if(filters.type) filters.id_type = filters.type.value
      delete filters.branch
      delete filters.type
      return filters
    },

  },
  methods:{
    firstInit(){
      if(!this.isPusat) {
        if(!this.filter.branch)
        this.filter.branch = {value:this.userProfile.id_branch,label:this.userProfile.branch_name}
      }
    },
    submitFilter(){
      this.$refs.datatables.refresh()
    },
    resetFilter(){
      if(this.isPusat){
        this.filter.id_branch = ""
        this.filter.branch = null
      }
      this.filter.date_1 = ""
      this.filter.date_2 = ""
      this.filter.id_type = ""
      this.filter.type = null
      this.$refs.datatables.refresh()
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('stockopnames/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    },
    async exportExcel(){
      const filters = this.filter
      try {
        const res = await this.$http.get(`v1/stock_opname_export`,{params:filters,responseType: 'blob'})
        FileSaver.saveAs(res.data,'STOK OPNAME')
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created(){
    this.firstInit()
  }
}
</script>

<style>

</style>